@import "../../../../assets/scss/themes/base-theme";

.wrapper {
  :global(.ant-modal-content) {
    width: 36rem;
  }

  .highlighted {
    color: $text-emphasize-color;
  }

  .description {
    text-align: center;
  }
}

.errorText {
  color: $error-color;
  font-weight: bold;
}
