@import "../../assets/scss/themes/base-theme";

.tabsWrapper {
  height: 100%;
  background-color: $card-content-background-color;
  border-left: 1px solid $card-content-border-color;
  border-right: 1px solid $card-content-border-color;
  border-radius: 8px;

  :global(.ant-tabs-nav) {
    margin-top: 32px !important;
  }

  :global(.ant-card-bordered) {
    border: none;
  }
}
