@import "../../../../assets/scss/themes/base-theme";

:global(.ant-menu-inline) {
  .footerMenuItem {
    :global {
      .ant-menu-title-content {
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;

        .anticon {
          margin-left: auto;
        }
      }
    }
  }
}

.versionNumber {
  color: white;
  padding-left: 1.5rem;
  padding-right: 1rem;
  height: 2.5rem;
  line-height: 2.5rem;
}
