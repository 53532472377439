@import "../../../../../../../assets/scss/themes/base-theme";

:global(.ant-btn) {
  &.button {
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    height: 3.5rem;

    &:hover {
      opacity: 0.6;
    }

    :global {
      .anticon {
        margin-right: 1rem;
        font-size: 1.375rem;
        margin-top: -0.25rem;
      }
    }
  }

  &.dangerButton {
    border-color: $workbook-task-reject-danger-button-color;
    color: $workbook-task-reject-danger-button-color;

    &:hover {
      border-color: $workbook-task-reject-danger-button-color;
      color: $workbook-task-reject-danger-button-color;
    }
  }

  &.normalButton {
    border-color: $workbook-task-reject-normal-button-color;
    color: $workbook-task-reject-normal-button-color;

    &:hover {
      border-color: $workbook-task-reject-normal-button-color;
      color: $workbook-task-reject-normal-button-color;
    }
  }

  &.warningButton {
    border-color: $workbook-task-reject-warning-button-color;
    color: $workbook-task-reject-warning-button-color;

    &:hover {
      border-color: $workbook-task-reject-warning-button-color;
      color: $workbook-task-reject-warning-button-color;
    }
  }
}

.space {
  width: 100%;
}

.content {
  padding: 2.875rem 2.75rem;
}

.title {
  text-align: center;
}

.desktop {
  zoom: 0.8;
  -moz-transform: scale(
    0.8
  ); // only for Firefox, because zoom is not supported in Firefox
}
