@import "../../../../assets/scss/themes/base-theme";

.comment {
  width: 100%;
}

.notifierEmail {
  display: inline-block;
  margin-left: 0.25rem;
  text-decoration: underline;
  color: $text-highlight-color;
}

.notifierName {
  color: $text-highlight-color;
}
