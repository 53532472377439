@import "../../../../assets/scss/themes/base-theme";

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
}
