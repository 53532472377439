@import "../../../../assets/scss/themes/base-theme";

.wrapper {
  width: 100%;
}

.introText {
  font-weight: 600;
  font-size: $text-font-size-sm;
  line-height: 1.125rem;
  color: $description-color;
  text-align: center;
}
