@import "../../../../../../../assets/scss/themes/base-theme";

.desktop {
  zoom: 0.8;
  -moz-transform: scale(
    0.8
  ); // only for Firefox, because zoom is not supported in Firefox
}

.itemTitle {
  text-align: center;
}
