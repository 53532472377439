@import "../../../../../../assets/scss/themes/base-theme";

.workbookTasksWrapper {
  padding: 1rem 0;
}

.task {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.03);
  border-radius: 0.5rem;
  background-color: #ffffff;
  min-height: 3.75rem;
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: $workbook-tasks-tab-tasks-task-color;
  width: 100%;
  flex-direction: column;
}

.wrapper {
  padding: 1rem;
}

.descriptionsWrapper {
  margin-top: 0.5rem;

  :global {
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
}
