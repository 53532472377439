@import "../../../../assets/scss/themes/base-theme";

.form {
  display: flex;

  :global {
    .ant-form-item {
      width: 100%;
    }

    .ant-form-item-control-input {
      max-height: 24.0625rem;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 1rem;
    }

    .ant-form-item-control-input-content {
      height: 100%;
    }
  }
}

.radiosWrapper {
  &:global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0.25rem;
  }

  :global {
    .ant-radio-wrapper {
      border-bottom: 1px solid $divider-color;
      height: 3rem;
      display: flex;
      align-items: center;

      .ant-radio {
        top: 0;

        + span {
          display: flex;
          justify-content: space-between;
          width: 100%;

          span {
            flex: 1;

            &:nth-of-type(2) {
              text-align: right;
            }
          }
        }
      }
    }

    .ant-radio-checked {
      + span {
        color: $primary-color;
      }
    }
  }
}

.contractorName {
  font-weight: 600;
  font-size: 0.875rem;
  padding-right: 0.5rem;
}

.contractorTown {
  font-weight: 400;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.spinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
