@import "../../../assets/scss/themes/base-theme";

.description {
  font-size: 1.125rem;
}

.button {
  &:global(.ant-btn) {
    font-weight: 700;
    font-size: 1rem;
  }
}
