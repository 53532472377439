@import "../../assets/scss/themes/base-theme";

.card {
  overflow: auto;
  flex: auto;

  &:global(.ant-card-bordered) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
