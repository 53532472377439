@import "../../../../../../assets/scss/themes/base-theme";

.content {
  padding: 1rem;
  display: flex;
  align-items: center;

  .identifier {
    font-weight: bold;
    color: $attached-failure-primary-text-color;
  }

  .address {
    color: $attached-failure-secondary-text-color;
  }

  span {
    flex: 1;
    text-align: center;

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  }
}
