@import "../../../assets/scss/themes/base-theme";

@import "assets/scss/abstracts/mixins";

.buttonContainer {
  text-align: right;
  margin-top: 1rem;
  
  @include respond-below(lg) {
    margin-bottom: 3rem;
  }
}

.wrapper {
  width: 56.25rem;
  margin: 0 1rem;
}
