.ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  border-color: $button-border-color;

  &.ant-btn-primary:not([disabled]) {
    border-color: $button-primary-border-color;
  }

  > span {
    vertical-align: middle;
  }

  .anticon {
    height: 1em;
  }
}

.ant-btn-link {
  border-color: transparent;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0.375rem;
}
