@import "../../../../assets/scss/themes/base-theme";

.deleteButton {
  &:global(.ant-btn) {
    position: absolute;
    top: 0.75rem;
    right: 1.125rem;
    z-index: 1;
  }
}

.space {
  width: 100%;
}
