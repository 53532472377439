@import "../../../assets/scss/themes/base-theme";

.uploadIcon {
  font-size: 2rem;
}

.spinWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadText {
  margin-top: 0.5rem;
}
