@import "variables";

// How to reuse ant variables
// 1. Pick a variable form here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// 2. Convert it to sass and place it in ANT VARS section
// 3. You can also override it by using a new value for that variable.
// 4. Define only new variables when there's no variable for the same purpose in the above file. E.g: $primary-color.
// 5. font-sizes, margins, sizes etc go to _variables.scss. Colors, bg-colors, border-radiuses etc come in this file.

$color-orange-1: #fbb631;

$color-blue-1: #009cbd;

$color-green-1: #128480;
$color-green-2: #27aaa5;

$color-light-1: #ffffff;

$color-gray-1: #273745;
$color-gray-2: #506a82;
$color-gray-3: #718ba4;
$color-gray-4: #abb8c5;
$color-gray-5: #c3cdd7;
$color-gray-6: #dbe4eb;
$color-gray-7: #f1f5f8;
$color-gray-8: #fafcfc;

$light-success-color: #48c96c;

// ##############################################################################################################################
//                                                             ANT VARS
// ##############################################################################################################################

// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// After editing this file, need to delete node_modules/.cache folder and restart application

$border-radius-base: 15px;
$checkbox-border-radius: 2px;
$table-border-radius-base: 0;

// -------- Colors -----------
$primary-color: $color-green-1;

// >>> Info
$info-color: #273b76;
$info-color-deprecated-bg: #d2eaff;
$info-color-deprecated-border: #d2eaff;

// >>> Success
$success-color: #276d41;
$success-color-deprecated-bg: #ddfce8;
$success-color-deprecated-border: #ddfce8;

// >>> Warning
$warning-color: #f68b0d;
$warning-color-deprecated-bg: #ffefb6;
$warning-color-deprecated-border: #ffefb6;

// >>> Error
$error-color: #d11515;
$error-color-deprecated-bg: #ffd9d9;
$error-color-deprecated-border: #ffd9d9;

// Base Scaffolding Variables
$font-family: "Nunito", sans-serif;
$text-color: $color-gray-1;
$text-color-secondary: $color-gray-4;
$heading-color: $text-color;

// Layout
$layout-header-background: $color-light-1;
$layout-sider-background: $color-gray-1;

// Avatar
$avatar-bg: $color-gray-7;

// Menu
$menu-bg: transparent;
$menu-item-color: $color-light-1;
$menu-item-active-bg: #33485a;
$menu-highlight-color: #ffffff;
$menu-inline-submenu-bg: #1a252e;
$menu-popup-bg: #1a252e;

// Divider
$divider-color: #e9eef1;

// Button
$btn-primary-bg: $color-gray-1;

// Table
$table-header-bg: #f4f7f9;

// Form
$input-bg: #f7f9fb;
$radio-button-bg: #eef3f3;
$select-background: #f7f9fb;
$switch-color: $light-success-color;
$picker-bg: #f7f9fb;

// Alert
$alert-info-bg-color: #e6f7ff;
$alert-info-border-color: #91d5ff;

// ##############################################################################################################################
//                                                        Basic theme variables
// ##############################################################################################################################

$warning-color-light-bg-color: #fff4d2;
$error-color-light-bg-color: #ffe8e6;

$sidebar-divider-border-color: #364d61;

$menu-collapser-bg-color: $color-gray-7;
$menu-collapser-color: $color-gray-3;

$default-layout-bg-color: $color-gray-7;

$button-border-color: #e9eef1;
$button-primary-border-color: $color-gray-1;
$button-icon-type-bg-color: $color-gray-7;
$button-icon-warning-variant-bg-color: #efc13f;
$button-icon-warning-variant-color: #000000;

$table-settings-item-color: $color-gray-2;
$table-results-color: $color-gray-3;

$pagination-active-item-bg-color: $color-gray-7;

$fieldset-fields-wrapper-border-color: $color-gray-7;
$fieldset-fields-wrapper-border-radius: 0.75rem;

$light-icon-button-color: $color-gray-4;

$select-tag-bg-color: $primary-color;
$select-tag-color: $color-light-1;

$mobile-menu-header-bg: linear-gradient(
  90.14deg,
  #2c3339 2.72%,
  #2d465b 106.2%
);
$mobile-menu-header-color: #ffffff;
$mobile-menu-header-border-color: rgba(255, 255, 255, 0.3);
$mobile-page-layout-header-bg: linear-gradient(
  90.14deg,
  #2c3339 2.72%,
  #2d465b 106.2%
);
$mobile-page-layout-color: #ffffff;

$failure-card-border-color: #e9eef1;
$attached-failure-card-background-color: #ffffff;
$attached-failure-primary-text-color: $color-gray-1;
$attached-failure-secondary-text-color: $color-gray-2;

$overflowed-button-radio-group-bg: linear-gradient(
  113.45deg,
  rgba(255, 255, 255, 0.36) 0%,
  rgba(255, 255, 255, 0) 92.19%
);
$overflowed-button-active-radio-group-bg-color: #ffffff;
$overflowed-button-radio-border-color: rgba(255, 255, 255, 0.2);

$fieldset-fields-wrapper-border-color: $color-gray-7;
$fieldset-fields-wrapper-border-radius: 0.75rem;

$light-icon-button-color: $color-gray-4;

$description-color: $color-gray-2;

$selected-menu-item-color: $color-orange-1;

$breadcrumb-link-color: $color-blue-1;

$vertical-data-descriptions-title-color: #718ba4;
$vertical-data-descriptions-content-color: $color-gray-2;
$vertical-data-descriptions-title-color-form-item-variant: #273745;

$failure-assign-modal-card-border-color: $color-gray-6;
$failure-assign-modal-content-color: $color-gray-2;
$failure-assign-modal-search-addon-border-color: #2c918b;

$text-highlight-color: $color-blue-1;
$text-emphasize-color: $color-green-2;

$register-card-footer-border-color: $color-gray-5;

$workbook-tasks-tab-tasks-task-color: #506a82;

$failure-view-repairman-heading-page-title-color: #fff;

$dark-container-card-bg-color: #ecf1f5;
$dark-container-title-color: #506a82;

$workbook-task-card-wrapper-bg-color: #ecf1f5;
$workbook-task-card-content-bg-color: #fff;
$workbook-task-card-title-color: #506a82;
$workbook-task-card-wrapper-done-bg-color: #def1ed;
$workbook-task-card-wrapper-rejected-bg-color: #ffe6e6;
$workbook-task-card-changed-date-color: #506a82;
$workbook-task-card-success-button-color: #fff;
$workbook-task-card-item-actions-bg-color: #dbe4eb;
$workbook-task-card-edit-button-color: #718ba4;
$workbook-task-card-delete-button-color: #d11515;
$workbook-task-card-delete-button-after-border-color: #dbe4eb;

$workbook-task-reject-danger-button-color: #d11515;
$workbook-task-reject-normal-button-color: #273745;
$workbook-task-reject-warning-button-color: #fb9e31;

$workbook-tasks-section-task-bg-color: #ffffff;
$workbook-tasks-section-task-color: #273745;

$add-workbook-task-modal-task-border-color: #e5ebef;
$add-workbook-task-modal-already-added-task-border-color: #f0f8f7;
$add-workbook-task-modal-input-border-color: #128480;
$add-workbook-task-modal-input-placeholder-color: #c3ccde;

$add-task-item-modal-category-bg-color: #fff;
$add-task-item-modal-category-active-bg-color: #ffa800;
$add-task-item-modal-category-active-color: #fff;

$set-item-count-wrapper-bg-color: #e5ebef;
$set-item-count-button-bg-color: #ffffff;
$set-item-count-increase-button-color: #128480;
$set-item-count-decrease-button-color: #d11515;
$set-item-count-left-content-bg-color: #ffffff;
$set-item-count-input-border-color: #adb5bd;
$set-item-count-input-bg-color: #ffffff;

$repairer-failure-list-attach-button-bg-color: #f1f5f8;
$repairer-failure-list-active-attach-button-bg-color: #d5e8ea;
$repairer-failure-list-selected-failure-card-border-color: $primary-color;

$floating-attach-bar-header-bg-color: #2d4357;
$floating-attach-bar-button-bg: linear-gradient(
  113.45deg,
  rgba(255, 255, 255, 0.36) 0%,
  rgba(255, 255, 255, 0) 92.19%
);
$floating-attach-bar-button-color: #fafcfc;
$floating-attach-bar-header-text-color: #fff;
$floating-attach-bar-main-help-text-color: #7993aa;
$floating-attach-bar-selected-color-text-color: #273745;
$floating-attach-bar-main-bg-color: #fff;
$floating-attach-bar-attach-button-bg-color: #efc13f;
$floating-attach-bar-attach-button-color: #273745;
$floating-attach-bar-attach-button-disabled-bg-color: rgba(39, 55, 69, 0.5);
$floating-attach-bar-attach-button-disabled-color: rgba(255, 255, 255, 0.5);
$floating-attach-bar-attach-button-disabled-border-color: rgba(39, 55, 69, 0.5);

$alert-info-alert-icon-color: #1890ff;

$header-label-color: #aab1b9;

$card-content-background-color: $color-light-1;
$card-content-border-color: #f0f0f0;
