@import "../../../../../../assets/scss/themes/base-theme";

.recommendedFixingDaysItem {
  :global {
    .ant-descriptions-item-content {
      color: $primary-color;
      font-weight: bold;
    }
  }
}
