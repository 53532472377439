@import "../../../../../../../assets/scss/themes/base-theme";

.title {
  text-align: center;
}

.content {
  padding: 3rem 0;
}

.modal {
  :global {
    .ant-modal-close {
      margin-top: 1rem;
      margin-right: 1rem;
      color: $text-color;

      .ant-modal-close-x {
        font-size: 2.5rem;
      }
    }
  }
}

.longModal {
  &:global(.ant-modal) {
    top: 40px;
  }
}
