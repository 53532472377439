@import "../../../../../../../../assets/scss/themes/base-theme";

.wrapper {
  background: $set-item-count-wrapper-bg-color;
  border-radius: 12px;
  padding: 12px 12px 20px 12px;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  margin-top: 48px;
}

.content {
  display: flex;
  column-gap: 6px;
}

.button {
  background: $set-item-count-button-bg-color;
  box-shadow: 0rem 0rem 1.1946rem rgba(17, 38, 146, 0.1);
  border-radius: 12px;
  width: 148px;
  height: 148px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 76px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.increaseButton {
    color: $set-item-count-increase-button-color;
  }

  &.decreaseButton {
    color: $set-item-count-decrease-button-color;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.leftContent {
  background: $set-item-count-left-content-bg-color;
  border-radius: 12px;
  padding: 32px;
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 0; // to make long title inside not overflow container
}

.details {
  min-width: 0; // to make long title inside not overflow container
}

.manufacturer {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: $primary-color;
}

.name {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
}

.input {
  &:global(.ant-input-number) {
    margin-bottom: 10px;
    width: 150px;
    height: 110px;
    display: flex;
    border: 0.0342rem dashed $set-item-count-input-border-color;
    border-radius: 4px;
    background-color: $set-item-count-input-bg-color;

    :global(.ant-input-number-input) {
      height: 100%;
      text-align: center;
      font-weight: 800;
      font-size: 58px;
      line-height: 75px;
      color: $primary-color;
    }
  }
}

.unit {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
}

.addButton {
  &:global(.ant-btn) {
    height: 70px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
  }
}

.inputWrapper {
  margin-left: 32px;
}

.image {
  margin-top: 16px;
  width: 128px;
  height: 128px;
}
