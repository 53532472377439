@import "../../../../assets/scss/themes/base-theme";

.textsWrapper {
  line-height: 1.3;
}

.icon {
  &:global(.anticon) {
    color: $color-gray-4;
    font-size: 18px;
    vertical-align: -0.32rem;
  }
}

:global(.ant-avatar) {
  &.avatar {
    line-height: 1.875rem !important;
  }
}
