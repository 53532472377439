@import "../../assets/scss/themes/base-theme";

.space {
  width: 100%;
  gap: 0 !important;
}

.card {
  overflow: hidden !important;
}
