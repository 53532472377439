@import "../../assets/scss/themes/base-theme";

.checkboxMinus {
  :global {
    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 7px;
      transform: rotate(0deg) scale(1) translate(25%, -80%);
      border-right: 0;
    }
  }
}
