@import "../../../../assets/scss/themes/base-theme";

.uploadIcon {
  font-size: 2rem;
}

.space {
  width: 100%;
  align-items: center;
}
