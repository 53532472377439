@import "../../../../assets/scss/themes/base-theme";

.collapser {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: $menu-collapser-bg-color;
  position: absolute;
  left: calc(100% + 0.875rem);
  top: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: $menu-collapser-color;
  border: none;
  padding: 0;
  cursor: pointer;
}

.collapsedIcon {
  transform: rotate(180deg);
}
