@import "../../../assets/scss/themes/base-theme";

.loginButton {
  &:global(.ant-btn) {
    font-weight: 700;
    font-size: 1rem;
  }
}

.description {
  font-size: 1.125rem;
}
