@import "../../../assets/scss/themes/base-theme";

.button {
  &:global(.ant-btn) {
    font-weight: 700;
    font-size: 1rem;
  }
}

.space {
  width: 100%;
}
