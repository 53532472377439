@import "../../../assets/scss/themes/base-theme";

.container {
  display: flex;
}

.icon {
  font-size: 16px;
}

.indicator {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
}
