@import "../../../../assets/scss/themes/base-theme";

.wrapper {
  padding: 0.75rem;
  background-color: $workbook-task-card-wrapper-bg-color;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;

  &.done {
    background-color: $workbook-task-card-wrapper-done-bg-color;
  }

  &.rejected {
    background-color: $workbook-task-card-wrapper-rejected-bg-color;
  }

  &.desktop {
    zoom: 0.8;
    -moz-transform: scale(
      0.8
    ); // only for Firefox, because zoom is not supported in Firefox
  }
}

.content {
  background: $workbook-task-card-content-bg-color;
  border-radius: 0.75rem;
  padding: 1rem;
}

.title {
  color: $workbook-task-card-title-color;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  margin-left: 14px;
}

.taskTitle {
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.875rem;
  margin-bottom: 2rem;
  display: flex;
}

.buttonWrapper {
  width: 18.75rem;
  max-width: 100%;

  .desktop & {
    width: 15.625rem;
  }
}

:global(.ant-btn) {
  &.button {
    height: 3.125rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.875rem;
    text-transform: uppercase;

    :global {
      .anticon {
        font-size: 1.25rem;
        margin-top: -0.125rem;

        + span {
          margin-left: 0.75rem;
        }
      }
    }
  }

  &.successButton {
    background: $primary-color;
    border: 1px solid $primary-color;
    color: $workbook-task-card-success-button-color;

    &:hover,
    &:focus,
    &:active {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: $workbook-task-card-success-button-color;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.dangerButton {
    border: 1px solid $error-color;

    &:hover {
      opacity: 0.6;
    }
  }
}

.icon {
  margin-right: 1rem;
  font-size: 1.625rem;

  &.doneIcon {
    color: $light-success-color;
  }

  &.rejectedIcon {
    color: $error-color;
  }
}

.statusChangedDateWrapper {
  font-size: 1rem;
  color: $workbook-task-card-changed-date-color;
  font-weight: 700;
  margin-left: auto;
}

.statusChangedDateIcon {
  font-size: 20px;
  margin-right: 0.375rem;
}

.changedByWrapper {
  display: flex;
}

.changedBy {
  font-weight: 700;
  font-size: 1rem;
  color: $primary-color;
  text-align: right;
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.item {
  background: $workbook-task-card-content-bg-color;
  border-radius: 0.75rem;
  display: flex;
}

.itemManufacturer {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: $primary-color;
  margin-bottom: 0.25rem;
}

.itemName {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
}

.itemCount {
  margin-left: auto;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
}

.itemDetails {
  padding: 1rem;
  display: flex;
  align-items: center;
  flex: 1;
}

.itemActions {
  border-left: 1px solid $workbook-task-card-item-actions-bg-color;
  display: flex;
  flex-direction: column;
}

.editButton,
.deleteButton {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 3.25rem;
  height: 3.25rem;
}

.editButton {
  color: $workbook-task-card-edit-button-color;
}

.deleteButton {
  position: relative;
  color: $workbook-task-card-delete-button-color;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    border-top: 1px solid $workbook-task-card-delete-button-after-border-color;
  }
}

.buttonIcon {
  font-size: 1.375rem;
}
