@import "../../../../assets/scss/themes/base-theme";

.deleteButton {
  &:global(.ant-btn) {
    position: absolute;
    top: 0.75rem;
    right: 1.125rem;
    z-index: 1;
  }
}

.space {
  width: 100%;
}

.invite {
  display: flex;
  justify-content: flex-end;
}

.registrationState {
  margin-left: 0.25rem;
  display: inline-flex;
}
