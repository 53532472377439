@import "../../../../assets/scss/themes/base-theme";

.deleteButton {
  &:global(.ant-btn) {
    z-index: 1;
  }
}

.actionButtons {
  margin-bottom: 1.5rem;
  height: 2rem;
}

.existingContactSelector {
  width: 100%;
  margin-bottom: 2rem;
}

.space {
  width: 100%;
}

.invite {
  display: flex;
  justify-content: flex-end;
} 

.registrationState {
  margin-left: 0.25rem;
  display: inline-flex;
}
