@import "../../../../../../assets/scss/themes/base-theme";

.task {
  background-color: $workbook-tasks-section-task-bg-color;
  box-shadow: 0px 1.31344px 2.62688px -0.656721px rgba(0, 0, 0, 0.03);
  border-radius: 0.5rem;
  display: flex;
  font-weight: 700;
  font-size: 1rem;
  color: $workbook-tasks-section-task-color;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  min-height: 3rem;
}
