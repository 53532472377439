// How to reuse ant variables
// 1. Pick a variable form here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// 2. Convert it to sass and place it in ANT VARS section
// 3. You can also override it by using a new value for that variable.
// 4. Define only new variables when there's no variable for the same purpose in the above file. E.g: $primary-color.
// 5. font-sizes, margins, sizes etc come in this file. Colors, bg-colors etc go to _theme.scss file

// https://ant.design/components/layout/#breakpoint-width
$grid-breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
);

// Basic theme variables

$text-font-size-lg: 1rem;
$text-font-size-md: 0.875rem;
$text-font-size-sm: 0.8125rem;
$text-font-size-xs: 0.75rem;
$text-font-size-xxs: 0.6875rem;

$heading-font-size-1: 2.5rem;
$heading-font-size-2: 1.875rem;
$heading-font-size-3: 2.5rem;
$heading-font-size-4: 1.875rem;
$heading-font-size-5: 1.25rem;

$xxl-button-size: 3.5625rem;

// ##############################################################################################################################
//                                                             ANT VARS
// ##############################################################################################################################

// These values doesnt change from theme to theme.
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// After editing this file, need to delete node_modules/.cache folder and restart application

$font-size-base: $text-font-size-md;
$font-size-lg: $text-font-size-lg;
$font-size-sm: $text-font-size-sm;
$heading-1-size: $heading-font-size-1;
$heading-2-size: $heading-font-size-2;
$heading-3-size: $heading-font-size-3;
$heading-4-size: $heading-font-size-4;
$heading-5-size: $heading-font-size-5;

// Layout
$layout-header-padding: 0 1.5rem;
$layout-header-height: 3.25rem;

// Typography
$typography-title-font-weight: 700;

// Menu
$menu-item-boundary-margin: 0.25rem;

// Animation
$animation-duration-fast: 0.1s; // Tooltip

// Card
$card-radius: 0.5rem;
$card-padding-base: 2rem;

// Button
$btn-border-radius-base: 100px;
$btn-padding-horizontal-base: 0.625rem;
$btn-shadow: 0px 1.23515px 2.4703px -0.617574px rgba(0, 0, 0, 0.03);
$btn-padding-horizontal-base: 1.0625rem;

// Input
$input-padding-vertical-base: 0.25rem;
$input-padding-horizontal-base: 0.375rem;
$input-padding-vertical-lg: 0.375rem;
$input-padding-horizontal-lg: 0.6875rem;
$input-height-lg: 2.5rem;

// Modal
$modal-header-title-font-size: 1.625rem;
$modal-header-padding-vertical: 1.875rem;
$modal-header-padding-horizontal: 1.5rem;
$modal-body-padding: 1.5rem 3.5rem;
$modal-footer-padding-vertical: 1rem;
$modal-footer-padding-horizontal: 1rem;

// Form
$form-item-margin-bottom: 1rem;

// ##############################################################################################################################
//                                                        Basic theme variables
// ##############################################################################################################################

$mobile-page-layout-padding: 1.5rem;
