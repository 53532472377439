@import "../../assets/scss/themes/base-theme";

.fixedTitle {
  padding: 2rem 2rem 1.5rem 2rem;
  background-color: $card-content-background-color;
  border-left: 1px solid $card-content-border-color;
  border-right: 1px solid $card-content-border-color;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
