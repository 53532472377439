@import "../../../../../../../assets/scss/themes/base-theme";

.task {
  min-height: 7.75rem;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.75rem;
  border-radius: 0.625rem;
  border: 0.25rem solid $add-workbook-task-modal-task-border-color;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  width: 100%;

  &.taskAlreadyAdded {
    background-color: $add-workbook-task-modal-already-added-task-border-color;
    color: $primary-color;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.7;
  }
}
