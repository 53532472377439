@import "../../assets/scss/themes/base-theme";

.wrapper {
  :global {
    .ant-descriptions-item-label {
      font-weight: 400;
      font-size: $text-font-size-sm;
      line-height: 1.125rem;
      color: $vertical-data-descriptions-title-color;
    }

    .ant-descriptions-item-content {
      font-weight: 600;
      font-size: $text-font-size-sm;
      line-height: 1.125rem;
      color: $vertical-data-descriptions-content-color;
    }
  }

  &.formItemVariant {
    :global {
      .ant-descriptions-item-label {
        font-size: $text-font-size-md;
        color: $vertical-data-descriptions-title-color-form-item-variant;
      }

      .ant-descriptions-item-content {
        font-size: $text-font-size-md;
        color: $vertical-data-descriptions-title-color-form-item-variant;
      }
    }
  }
}
