.ant-descriptions-item-label {
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 700;
}

.ant-descriptions-item-content {
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: $description-color;
}

.ant-descriptions-row > th {
  padding-bottom: 0;
}

.ant-descriptions-row:not(:last-of-type) > td {
  padding-bottom: 1rem;
}
