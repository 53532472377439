@import "../../../../assets/scss/themes/base-theme";

.sectionTitle {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.row {
  width: 100%;
}

.invite {
  display: flex;
  justify-content: flex-end;
}

.registrationState {
  margin-left: 0.25rem;
  display: inline-flex;
}
