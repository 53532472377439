@import "../../../../assets/scss/themes/base-theme";

.icon {
  &:global(.anticon) {
    font-size: 1.25rem;
    vertical-align: sub;
    margin-left: 0.5rem;
  }
}

.space {
  width: 100%;
}

.spinWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.failureCardWrapper {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 0.75rem;

  &.selected {
    border-color: $repairer-failure-list-selected-failure-card-border-color;
  }
}

:global(.ant-btn) {
  &.attachButton {
    background: $repairer-failure-list-attach-button-bg-color;
    border-radius: 5.5rem;
    border-color: transparent;
    height: 3.5rem;
    padding: 0.875rem 1rem;
    font-weight: 700;
    font-size: 1.25rem;
    color: $primary-color;
    line-height: 1.5rem;

    &:hover,
    &:active,
    &:focus {
      background-color: $repairer-failure-list-attach-button-bg-color;
      border-color: transparent;
      color: $primary-color;
    }
  }

  &.attachButtonIsActive {
    background-color: $repairer-failure-list-active-attach-button-bg-color;

    &:hover,
    &:active,
    &:focus {
      background-color: $repairer-failure-list-active-attach-button-bg-color;
    }
  }
}

.attachIcon {
  font-size: 1.75rem;
  margin-right: 0.375rem;
}

.emptyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
