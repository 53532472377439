@import "../../../assets/scss/themes/base-theme";

.card {
  border: 1px solid $failure-assign-modal-card-border-color;
  border-radius: 0.75rem;
}

.cardHeader {
  padding: 1rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: $failure-assign-modal-content-color;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dbe4eb;
}

.cardMain {
  padding: 1rem;
}

.searchWrapper {
  margin-bottom: 0.5rem;
}

.reporterDescriptions {
  :global {
    .ant-descriptions-item-content {
      flex-wrap: wrap;
    }
  }
}
