@import "../../../../../../../assets/scss/themes/base-theme";

@import "src/assets/scss/abstracts/mixins";

.input {
  &:global(.ant-input) {
    height: 4rem;
    margin-bottom: 3.875rem;
    border: 3px solid $add-workbook-task-modal-input-border-color;
    margin-top: 0.75rem;
    font-weight: 700;
    font-size: 1.5625rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include placeholder {
      color: $add-workbook-task-modal-input-placeholder-color;
    }
  }
}

.inputWrapper {
  margin: 0 3rem;
}
