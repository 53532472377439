@import "../../../assets/scss/themes/base-theme";

.inputContainer {
  margin-top: 16px;
  padding: 16px;
  max-height: 300px;
  overflow: auto;

  :global(.ant-picker-suffix) {
    font-size: 16px;
  }

  :global(.ant-picker) {
    width: 100%;
    padding: 6px 8px;
  }
}

.input {
  width: 100%;
  padding: 6px 8px;
}

.spinnerContainer {
  text-align: center;
}

.searchBtn {
  margin-left: 0.5rem;
}
