@import "../../assets/scss/themes/base-theme";

@import "assets/scss/abstracts/mixins";

@mixin make-container($padding-x: 1.5rem) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $padding-x;
  padding-left: $padding-x;
}

.container {
  @include make-container();

  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include respond-above($breakpoint) {
      max-width: $container-max-width;
    }
  }

  &.fluid {
    max-width: 100%;
  }
}
