.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  border-top: 1px solid #f0f0f0 !important;
  border-left: 1px solid #f0f0f0 !important;
  width: 13px;
  height: 13px;
  border-right: 0;
  border-bottom: 0;
  background: linear-gradient(315deg, transparent 50%, #fff 0);
  top: 4px;
}
