@import "../../assets/scss/themes/base-theme";

.title {
  margin-top: 1.875rem;
  font-weight: 700;
  font-size: 1.75rem;
}

.paragraph {
  &:global(.ant-typography) {
    margin-bottom: 1.5rem;
    white-space: pre-line;
  }
}

.content {
  text-align: center;
}
