@import "../../../assets/scss/themes/base-theme";

.tagWrapper {
  :global {
    .ant-tag {
      height: 2rem;
      display: inline-flex;
      align-items: center;
      padding: 0 1.5rem;
    }
  }
}

.headerDescriptionsRow {
  :global {
    h2.ant-typography {
      margin-bottom: 0;
    }

    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
}
