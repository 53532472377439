@import "../../../../assets/scss/themes/base-theme";

@import "../config";

.contentWrapper {
  padding: $sh-layout-padding-around-content $sh-layout-padding-around-content 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  padding-bottom: $sh-layout-padding-around-content;
  display: flex;
  min-height: 0;
  flex: 1;
  flex-direction: column;

  > * {
    width: 100%;
  }
}

.layoutContent {
  overflow: auto;
}
