@import "../../assets/scss/themes/base-theme";

.layout {
  &:global(.ant-layout) {
    min-height: 100vh;
    background-color: $default-layout-bg-color;

    :global {
      .ant-layout-content {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}
