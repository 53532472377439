.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.ant-upload-list-picture-card-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  margin-right: 0;
}

.ant-upload {
  color: $primary-color;
  width: 100%;

  &.ant-upload-select {
    border: 1px dashed $primary-color;
    border-radius: 1.25rem;
    cursor: pointer;
    padding: 1rem;
  }

  &.ant-upload-select-picture-card {
    width: 100%;
    padding: 100% 0 0 0;
    position: relative;
    margin-right: 0;
  }
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  .ant-upload-list-item-list-type-picture-card & {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 1.75rem;
    width: 1.75rem;
  }

  .ant-upload-list-item-list-type-text & {
    padding: 0;
  }
}

.ant-form-item-has-error {
  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload.ant-upload-select {
    border-color: $error-color;
  }
}
