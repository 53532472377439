@import "../../../assets/scss/themes/base-theme";

.draggableArea {
  max-height: 540px;
  margin-top: 24px;
  overflow: auto;
}

.draggableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  margin-bottom: 8px;

  & > *,
  & > .actionElement {
    margin-right: 8px;
  }
}

.itemText {
  flex: 1;
}

.grabIcon {
  width: 24px;
}

.grabIcon,
.actionElement {
  text-align: center;
}

.actionElement {
  width: 32px;
  display: inline-flex;
  justify-content: center;
}

.grabIcon,
.actionElement,
.itemText {
  &:global(.anticon) {
    margin-right: 8px;
  }
}

.itemText,
.actionElement {
  &:global(.locked) {
    color: $table-settings-item-color;
  }
}

.title {
  font-size: $text-font-size-lg;
  font-weight: 700;
}
