// sh: Sidebar header
$sh-layout-padding-around-content: 1.5rem !default;
$sh-layout-user-icon-color: $color-gray-4 !default;
$sh-layout-logo-wrapper-margin: 15px 0 0.625rem !default;
$sh-layout-sidebar-divider-border-color: $sidebar-divider-border-color !default;
$sh-layout-menu-title-transition: $animation-duration-fast !default;
$sh-layout-menu-group-title-font-size: $text-font-size-xxs !default;
$sh-layout-menu-group-title-color: $color-light-1 !default;
$sh-layout-menu-item-color: $menu-item-color !default;
$sh-layout-menu-overflow-figure-bg-color: $menu-item-active-bg !default;
