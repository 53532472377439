@import "../../../../assets/scss/themes/base-theme";

.assignButtonIcon {
  color: $primary-color;
}

.recommendedFixingDays {
  color: $primary-color;
}

.attachedFailuresIcon {
  font-size: 1.5rem;
  transform: rotate(-44.8deg);
  margin-right: 0.375rem;
  vertical-align: middle;
}

.attachedFailuresSup {
  color: $primary-color;
  margin-left: 0.25rem;
}

.bulkActionsIcon {
  &:global(.anticon) {
    font-size: 1.25rem;
  }
}

.nearDeadlineRow {
  background-color: $warning-color-light-bg-color;
}

.expiredDeadlineRow {
  background-color: $error-color-light-bg-color;
}
