@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self (false) - Whether or not to include current selector
/// @see https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }

  @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

// Respond above.
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// Respond below.
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
