@import "../../../assets/scss/themes/base-theme";

.tagWrapper {
  :global {
    .ant-tag {
      height: 2rem;
      display: inline-flex;
      align-items: center;
      padding: 0 1.5rem;
    }
  }
}

.headerDescriptionsRow {
  :global {
    h2.ant-typography {
      margin-bottom: 0;
    }

    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
}

.textWarning {
  color: $warning-color;

  &:global(.ant-typography) {
    color: $warning-color;
  }
}

.textError {
  color: $error-color;

  &:global(.ant-typography) {
    color: $error-color;
  }
}

.infoIcon {
  font-size: 1.25rem;
}

.name {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
}
