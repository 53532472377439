@import "../../../../../assets/scss/themes/base-theme";

.wrapper {
  padding: 1.5rem;
  background-color: $dark-container-card-bg-color;
  border-radius: 0.75rem;
}

.title {
  text-transform: uppercase;
  color: $dark-container-title-color;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.icon {
  margin-right: 1rem;
  font-size: 1.375rem;
  color: $dark-container-title-color;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1.375rem;
}
