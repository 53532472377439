@import "../../assets/scss/themes/base-theme";

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 32px;
}

.headerContainerWithTitle {
  @extend .headerContainer;

  padding: 0 0 24px 0;
}

.titleContainer {
  display: flex;
  align-items: baseline;

  > h4 {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

.resetButton {
  margin-left: 32px;
}

.resetButton > .anticon {
  font-size: 16px;
}

.results {
  align-self: center;
  color: $table-results-color;

  > sup {
    top: -0.3em;
  }
}

.divider {
  &:global(.ant-divider) {
    height: 100%;
    margin: 0 8px !important;
  }
}

.extraContent > * {
  margin-left: 8px;
}

.extraContent > *:first-child {
  margin-left: 0;
}

.rightContent {
  padding-right: 1.25rem;
}
