@import "../../../../assets/scss/themes/base-theme";

@import "../config";

.divider {
  &:global(.ant-divider) {
    margin: 0.3125rem 0.875rem;
    border-color: $sh-layout-sidebar-divider-border-color;
    width: calc(100% - 2 * 0.875rem);
    min-width: 0;
  }
}
