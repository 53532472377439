@import "../../assets/scss/themes/base-theme";

.footer {
  padding: 1.75rem 0;
  border-top: 1px solid $register-card-footer-border-color;
  text-align: center;
  margin-top: 2rem;
}

.space {
  width: 100%;
}

.logo {
  max-height: 3.75rem;
}
