@import "../../../../assets/scss/themes/base-theme";

.wrapper {
  height: 4.1875rem;
  display: flex;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  align-items: center;
  background: $mobile-menu-header-bg;
  border-bottom: 1px solid $mobile-menu-header-border-color;
}

.names {
  margin-left: auto;
}

.userName {
  font-weight: bold;
  font-size: 1rem;
  display: inline-block;
  line-height: 1.875rem;
  padding-left: 0.75rem;
  color: $mobile-menu-header-color;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $mobile-menu-header-color;
}

.logoutWrapper {
  color: $mobile-menu-header-color;
  font-size: 1.5rem;
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  border-left: 1px solid $mobile-menu-header-color;
  cursor: pointer;
}

.logo {
  max-height: 3.75rem;
}
