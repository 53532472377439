@import "../../assets/scss/themes/base-theme";

.header {
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  background: $mobile-page-layout-header-bg;
  color: $mobile-page-layout-color;
  align-items: center;
}

.content {
  padding: $mobile-page-layout-padding;
  min-height: 100%;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
