@import "../../../../assets/scss/themes/base-theme";

.form {
  :global(.ant-form-item-control-input) {
    max-height: 24.0625rem;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 1rem;
  }
}

.repairmanName {
  font-weight: 600;
  font-size: 0.875rem;
  padding-right: 0.5rem;
}

.checkboxGroupWrapper {
  &:global(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0.25rem;
  }

  :global {
    .ant-checkbox-wrapper {
      border-bottom: 1px solid $divider-color;
      height: 3rem;
      display: flex;
      align-items: center;

      .ant-checkbox {
        top: 0;
      }

      + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }
}

.spinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
