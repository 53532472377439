@import "../../assets/scss/themes/base-theme";

.extraMargin {
  margin-top: 24px;
}

.tabs {
  :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 600;
    }
  }
}
