@import "../../../../assets/scss/themes/base-theme";

.wrapper {
  border-radius: 12px;
  border: 12px solid $failure-card-border-color;
  background-color: $failure-card-border-color;
}

.content {
  padding: 1rem;
  border-radius: 12px;
  background-color: #fff;
}

.topRightDescriptions {
  :global {
    .ant-descriptions-view {
      table {
        width: auto;
      }

      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }
  }
}

.reportedAtItem {
  width: 320px;
}

.recommendedFixingDaysItem {
  :global {
    .ant-descriptions-item-content {
      color: $primary-color;
      font-weight: bold;
    }
  }
}

.descriptions {
  :global {
    .ant-descriptions-view {
      .ant-descriptions-item:not(:last-of-type) {
        padding-right: 1.25rem;
      }
    }
  }
}

.attachedFailuresIcon {
  transform: rotate(-44.8deg);
  margin-right: 0.375rem;
  margin-top: -0.5rem;
  vertical-align: middle;
  color: $primary-color;
}

.attachedFailuresSup {
  color: $primary-color;
  margin-left: 0.25rem;
}
