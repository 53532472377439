@import "../../../../../../assets/scss/themes/base-theme";

.wrapper {
  border-radius: $border-radius-base;
  background-color: $attached-failure-card-background-color;
}

.divider {
  &:global(.ant-divider) {
    margin: 0;
  }
}
