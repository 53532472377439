@import "../../../../../assets/scss/themes/base-theme";

.header {
  background: $floating-attach-bar-header-bg-color;
  border-radius: 1.25rem 1.25rem 0px 0px;
  padding: 1rem;
}

:global(.ant-btn) {
  &.button {
    background: $floating-attach-bar-button-bg;
    filter: drop-shadow(0px 0px 1rem rgba(17, 38, 146, 0.1));
    border-radius: 1.875rem;
    height: 3.5rem;
    text-transform: uppercase;
    color: $floating-attach-bar-button-color;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    padding: 1rem;

    &:disabled {
      background: $floating-attach-bar-attach-button-disabled-bg-color;
      color: $floating-attach-bar-attach-button-disabled-color;
      border-color: $floating-attach-bar-attach-button-disabled-border-color;
    }
  }

  &.attachButton:not(:disabled) {
    background: $floating-attach-bar-attach-button-bg-color;
    color: $floating-attach-bar-attach-button-color;
    border-color: $floating-attach-bar-attach-button-bg-color;
  }
}

.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.headerText {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: $floating-attach-bar-header-text-color;
}

.mainHelpText {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: $floating-attach-bar-main-help-text-color;
  white-space: pre-wrap;
}

.main {
  padding: 1.25rem;
  text-align: center;
  background: $floating-attach-bar-main-bg-color;
}

.selectedCount {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: $floating-attach-bar-selected-color-text-color;
}

:global(.anticon) {
  &.icon {
    font-size: 1.875rem;
    margin-right: 1rem;
    vertical-align: bottom;
  }
}
