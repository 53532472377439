@import "../../../../../../assets/scss/themes/base-theme";

.space {
  width: 100%;
}

.addNewTask {
  position: absolute;
  top: -4.625rem;
  right: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.875rem;
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wrapper {
  position: relative;
}

.addNewTaskIcon {
  font-size: 1.75rem;
}
