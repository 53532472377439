@import "../../../../assets/scss/themes/base-theme";

.sectionTitle {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.row {
  width: 100%;
}
