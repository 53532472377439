@import "../../../../assets/scss/themes/base-theme";

.sectionTitle {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.introText {
  font-weight: 600;
  font-size: $text-font-size-sm;
  line-height: 1.125rem;
  color: $description-color;
  text-align: center;
}

.isActiveSwitch {
  &:global(.ant-switch) {
    background-color: $error-color;
    background-image: none;
  }

  &:global(.ant-switch.ant-switch-checked) {
    background-color: $switch-color;
  }
}
