@import "../../assets/scss/themes/base-theme";

.wrapper {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
}

.item {
  font-weight: 600;
  font-size: $text-font-size-sm;
  line-height: 1.125rem;

  + .item {
    &::before {
      content: "/";
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

.link {
  font-weight: 500;
  font-size: $text-font-size-sm;
  line-height: 0.9375rem;
  color: $breadcrumb-link-color;
}
