@import "../../../assets/scss/themes/base-theme";

.emptyContent {
  display: flex;
  align-items: center;
}

.emptyIcon {
  margin-right: 8px;
  font-size: 16px;
}
