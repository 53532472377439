@import "../../assets/scss/themes/base-theme";

.statusDisplay {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statusBadge {
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
  border-radius: 50%;
}
