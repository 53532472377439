@import "../../assets/scss/themes/base-theme";

.icon {
  &:global(.anticon) {
    font-size: 1.0625rem;
  }
}

.lightIcon {
  &:global(.anticon) {
    color: $light-icon-button-color;
  }
}

:global(.ant-btn) {
  &.button {
    background-color: $button-icon-type-bg-color;

    &:not(.alternative):not(:hover) {
      border-color: transparent;
    }
  }

  &.alternative {
    background-color: transparent;
  }

  &.warning {
    background-color: $button-icon-warning-variant-bg-color;
    color: $button-icon-warning-variant-color;
  }

  &.xxl {
    height: $xxl-button-size;
    width: $xxl-button-size;

    :global {
      .anticon {
        font-size: 1.875rem;
      }
    }
  }
}
