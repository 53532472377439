// DON'T USE, IT MESSES UP BASE ANTD CONFIG VALUES
/* html {
  font-size: 62.5%;
} */

img {
  max-width: 100%;
}

fieldset {
  display: block;
  width: 100%;
}
