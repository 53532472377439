@import "../../../../assets/scss/themes/base-theme";

@import "../config";

.sideMenuNoLink {
  cursor: default;
}

.menuGroupTitle {
  padding: 0.5625rem 1.5rem;
  text-transform: uppercase;
  font-size: $sh-layout-menu-group-title-font-size;
  color: $sh-layout-menu-group-title-color;
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
}

.menuWrapper {
  :global {
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right-color: transparent;
    }

    .ant-menu-title-content {
      transition-duration: $sh-layout-menu-title-transition;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      color: $selected-menu-item-color;
    }

    .ant-menu-inline {
      .ant-menu-item {
        overflow: visible;

        &::after {
          border: none;
          width: 0.875rem;
          height: 0.875rem;
          background-color: $sh-layout-menu-overflow-figure-bg-color;
          top: 50%;
          right: 0;
          transform: translate(50%, -50%) rotate(45deg);
          transition: none;
        }
      }
    }

    .ant-menu-submenu-inline {
      .ant-menu-submenu-title {
        margin-bottom: 0;
      }
    }

    .ant-menu-inline-collapsed-tooltip {
      .ant-tooltip-inner {
        .anticon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
