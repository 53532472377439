@import "../../../../../../../../assets/scss/themes/base-theme";

.categories {
  background: linear-gradient(90.14deg, #2c3339 2.72%, #2d465b 106.2%);
  margin-top: 3.125rem;
  display: flex;
  row-gap: 2rem;
  column-gap: 2rem;
  padding: 1.625rem 3.5rem;
  margin: -1rem;
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
  overflow: auto;
}

.category {
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  background-color: $add-task-item-modal-category-bg-color;
  border-radius: 1.25rem;
  cursor: pointer;
  border: none;
  flex-shrink: 0;

  &.active {
    pointer-events: none;
    background-color: $add-task-item-modal-category-active-bg-color;
    color: $add-task-item-modal-category-active-color;
  }

  &:hover {
    opacity: 0.7;
  }
}

.itemManufacturer {
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.5625rem;
  color: $primary-color;
}

.itemName {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5625rem;
  min-width: 0;
}

.itemDetails {
  min-width: 0; // to fix truncated text in flexbox container https://css-tricks.com/flexbox-truncated-text/
}

.itemData {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.itemChooserCol {
  &:global(.ant-col) {
    display: flex;
  }
}

.itemImage {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  margin-left: 0.75rem;
}

.desktop {
  zoom: 0.8;
  -moz-transform: scale(
    0.8
  ); // only for Firefox, because zoom is not supported in Firefox
}
