@import "../../assets/scss/themes/base-theme";

.xs {
  font-size: $text-font-size-xs;
}

.sm {
  font-size: $text-font-size-sm;
}

.lg {
  font-size: $text-font-size-lg;
}
